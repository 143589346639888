/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from 'components/layout/Layout'

// Elements
import Breadcrumb from 'components/elements/BreadCrumb'
import Video from 'components/elements/Video'
import SEO from 'components/shared/SEO'
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

const Hero = styled.section`
  height: 470px;
  position: relative;
`

const HeroContent = styled.div`
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  padding-bottom: 50px;
`

const CTAImage = styled(Plaatjie)`
  position: relative;
  z-index: 2;
`

const Outsource = styled(ParseContent)`
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  background: white;
  padding: 35px;
  height: fit-content;
  position: relative;
  width: 300px;
  z-index: 1;
  
  & h3 {
    font-size: ${props => props.theme.font.size.l};
    line-height: ${props => props.theme.font.size.l};
  }

  @media (min-width: 992px) {
    margin: -60px 0px 0 80px;
  }

  @media screen and (max-width: 991px) {
    margin: 20px 0 0 0;
  }
`

const BannerImage = styled(Plaatjie)`
  height: 470px;
`


const PageTemplate = ({
  data: {
    page: { path, title, yoast_meta: yoast, acf },
  },
  pageContext
}) => {
  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
        image={{
          url:
            acf.banner.image.localFile &&
            acf.banner.image.localFile.childImageSharp.openGraph.src,
        }}
      />

      <Hero>
        <BannerImage image={acf.banner.image} />
        <HeroContent className='container d-flex flex-wrap'>
          <div className="col-lg-7 mt-5 mb-5">
            <ParseContent content={acf.banner.description} />
            <Breadcrumb data={title} className="py-0" pageContext={pageContext} />
          </div>
          <div className="col-lg-5 pl-lg-5">
            <CTAImage image={acf.cta.image} style={{borderRadius: 20, width: '100%', objectFit: 'cover'}} alt={acf.cta.image.title} />
          </div>
        </HeroContent>
      </Hero>

      <div className="container pb-5 mb-5 mb-lg-0">
        <div className="row">
          <div className="col-lg-6 mt-3">
            <div>
              <ParseContent content={acf.meta.description} />
            </div>
          </div>
          <div className="col-lg-6 d-flex justify-content-center">
            <Outsource content={acf.cta.description} />
          </div>
        </div>
        
        {(acf.meta.videos.description || acf.meta.videos.urls) && (
          <div className="pt-2">
            {acf.meta.videos.description && (
              <ParseContent content={acf.meta.videos.description} />
            )}
            {acf.meta.videos.urls && (
              <div className="d-flex flex-wrap">
                {acf.meta.videos.urls.map(({ url, duration, title: videoTitle }, index) => {
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={index} className="col-lg-4">
                      <Video key={title} title={videoTitle} url={url} duration={duration} />
                    </div>
                    ) 
                })}
              </div>
            )}
          </div>
        )}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        banner {
          image {
            title
            localFile {
              childImageSharp {
                gatsbyImageData(width: 2000, quality: 100)
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
          description
        }

        meta {
          description
          videos {
            description
            urls {
              title
              duration
              url
            }
          }
        }

        cta {
          image {
            title
            localFile {
              childImageSharp {
                gatsbyImageData(width: 400, quality: 100)
              }
            }
          }
          description
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate